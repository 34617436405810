import type { RecruitingSiteResponse } from '@flex-apis/recruiting';
import { PageType, SiteDesignPageType } from '@flex-apis/recruiting';
import { fetchI18nResources, getTranslationsProps } from '@flex-packages/i18n';
import { serverSideTranslations } from '@flex-packages/i18n/next/serverSideTranslations';
import {
  generateSiteDesign,
  urlPagePathMigrationHelper,
} from '@flex-private-packages/recruiting-site-builder/base';
import type { GetServerSideProps } from 'next';

import type { WithErrorProps } from '../../src';
import {
  fetchRecruitingSite,
  getSubdomainFromServerSidePropsContext,
  getUrlQueryParamFromServerSidePropsContext,
  handleServerSideError,
  handleSystemSubdomain,
  InternalCustomPage,
  useSendAccessLog,
  withErrorPage,
} from '../../src';
import { getServerAxios } from '../../src/utils/axios';

interface Props {
  recruitingSiteResponse: RecruitingSiteResponse;
  urlPagePath: string;
}

function InternalCustom({ recruitingSiteResponse, urlPagePath }: Props) {
  const siteDesign = generateSiteDesign(recruitingSiteResponse);

  const parsedUrlPagePath = urlPagePathMigrationHelper.toSiteDesignRendererData(
    {
      type: SiteDesignPageType.Custom,
      urlPagePath,
    }
  );

  useSendAccessLog({
    customerIdHash: siteDesign.customerIdHash,
    pageType: PageType.Site,
  });

  return (
    <InternalCustomPage
      siteDesign={siteDesign}
      urlPagePath={parsedUrlPagePath}
    />
  );
}

const InternalCustomWithErrorPage = withErrorPage(InternalCustom);
export default InternalCustomWithErrorPage;

type ServerSideProps = WithErrorProps<Props>;
export const getServerSideProps: GetServerSideProps<
  ServerSideProps
> = async context => {
  try {
    const axiosInstance = getServerAxios({
      req: context.req,
      res: context.res,
    });
    const subdomain = getSubdomainFromServerSidePropsContext(context);
    const urlPagePath = getUrlQueryParamFromServerSidePropsContext(
      context,
      'urlPagePath'
    );

    await handleSystemSubdomain({ context, axiosInstance, subdomain });

    const [recruitingSiteResponse, { props: translationProps }] =
      await Promise.all([
        fetchRecruitingSite(axiosInstance, { subdomain }),
        getTranslationsProps(
          serverSideTranslations,
          fetchI18nResources(require.context('../../locales'))
        )(context),
      ]);

    return {
      props: {
        recruitingSiteResponse,
        urlPagePath,
        ...translationProps,
      },
    };
  } catch (error) {
    return handleServerSideError(context, error);
  }
};
