import type {
  RecruitingSiteResponse,
  SiteDesignPageResponse,
} from '@flex-apis/recruiting';
import type { Nullable } from '@flex-packages/types';

import type { PageComponent } from '../page';

import {
  isHomePageComponent,
  parseSitePageDesign,
  urlPagePathMigrationHelper,
} from './logics';
import type { SiteDesignLink } from './site-design-link';
import { generateSiteDesignLink } from './site-design-link';

export interface SiteDesign {
  // site info
  readonly faviconImgUrl?: string;
  readonly seoTitle?: string;
  readonly seoDescription?: string;

  // design info
  readonly designTitle: string;

  // customer info
  readonly customerIdHash: string;
  readonly customerName: string;
  readonly customerAddress: string;
  readonly customerTelephoneNumber: string;
  readonly representativeEmail: string;

  // navigation data
  readonly headerTitle: string;
  readonly logoImgUrl: string;

  // page data
  readonly pages: Array<PageComponent>;

  // terms data
  readonly privacyPolicyUrl: string;

  // footer data
  readonly footerLinks: Array<SiteDesignLink>;
}

export function generateSiteDesign(
  recruitingSiteResponse: RecruitingSiteResponse
): SiteDesign {
  const { faviconUrl, seoTitle, seoMetaDescription, design } =
    recruitingSiteResponse;

  return {
    faviconImgUrl: faviconUrl,
    seoTitle: seoTitle,
    seoDescription: seoMetaDescription,

    designTitle: design.title,

    customerIdHash: design.customerIdHash,
    customerName: design.customerName ?? '',
    customerAddress: design.customerAddress ?? '',
    customerTelephoneNumber: design.customerTelephoneNumber ?? '',
    representativeEmail: design.representativeEmail ?? '',

    headerTitle: design.headerTitle ?? '',
    logoImgUrl: design.logoImageUrl ?? '',

    pages: getPagesList(recruitingSiteResponse).map(it => ({
      ...it,
      urlPagePath: it.urlPagePath
        ? urlPagePathMigrationHelper.toSiteDesignRendererData({
            urlPagePath: it.urlPagePath,
            type: it.type,
          })
        : null,
    })),

    privacyPolicyUrl: design.privacyPolicyLink ?? '',

    footerLinks: design.footerLinks.map(siteDesignLinkResponse =>
      generateSiteDesignLink({ siteDesignLinkResponse })
    ),
  };
}

function getPagesList(
  recruitingSiteResponse: RecruitingSiteResponse
): Array<PageComponent> {
  const { siteDesign, siteDesignPages } = validateOrMergeResponse(
    recruitingSiteResponse
  );

  // 서버 응답에서 Home 디자인이 없다면 에러를 던짐
  if (!siteDesign) throw new Error('siteDesign is null');

  const otherPages = siteDesignPages.map(siteDesignPageResponse =>
    parseSitePageDesign({ siteDesignPageResponse })
  );

  const homePage = parseSitePageDesign({
    siteDesignPageResponse: siteDesign,
  });

  return [homePage, ...otherPages];
}

function validateOrMergeResponse(
  recruitingSiteResponse: RecruitingSiteResponse
) {
  const { design } = recruitingSiteResponse;

  const initialValue: {
    siteDesign: Nullable<SiteDesignPageResponse>;
    siteDesignPages: SiteDesignPageResponse[];
  } = {
    siteDesign: null, // design 필드에 홈페이지가 존재하지 않으면 null
    siteDesignPages: [],
  };

  return design.siteDesignPages.reduce((acc, siteDesignPageV2Response) => {
    if (isHomePageComponent(siteDesignPageV2Response)) {
      return {
        ...acc,
        siteDesign: siteDesignPageV2Response, // design 필드에 홈페이지가 존재하면 업데이트
      };
    } else {
      return {
        ...acc,
        siteDesignPages: [...acc.siteDesignPages, siteDesignPageV2Response],
      };
    }
  }, initialValue);
}
