import type { SiteDesignPageResponse } from '@flex-apis/recruiting';
import {
  SiteDesignPageLinkType,
  SiteDesignPageType,
} from '@flex-apis/recruiting';

import type { PageComponent } from '../../page';
import {
  generateExternalSubPageComponent,
  generateInternalSubPageComponent,
  generateJobDescriptionListPageComponent,
  generateMainPageComponent,
} from '../../page';

export function parseEmptyString(value?: string) {
  // 문자열이 비어있으면 undefined로 변환
  return value === '' ? undefined : value;
}

export function parseFixedSizeList<T>(params: {
  list: Array<T>;
  size: number;
  defaultGenerator: () => T;
}): Array<T> {
  const { list, size, defaultGenerator } = params;

  const defaultList = Array.from({ length: size }, () => defaultGenerator());
  return [...list, ...defaultList].slice(0, size);
}

export function parseSitePageDesign(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): PageComponent;
export function parseSitePageDesign(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): PageComponent {
  return parseSitePageDesignFromSiteDesignPageResponse(params);
}

function parseSitePageDesignFromSiteDesignPageResponse(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): PageComponent {
  const { siteDesignPageResponse } = params;

  if (siteDesignPageResponse.type === SiteDesignPageType.Home) {
    return generateMainPageComponent({ siteDesignPageResponse });
  }

  if (siteDesignPageResponse.type === SiteDesignPageType.JobDescription) {
    return generateJobDescriptionListPageComponent({ siteDesignPageResponse });
  }

  if (
    siteDesignPageResponse.type === SiteDesignPageType.Custom &&
    siteDesignPageResponse.linkType === SiteDesignPageLinkType.Internal
  ) {
    return generateInternalSubPageComponent({ siteDesignPageResponse });
  }

  if (
    siteDesignPageResponse.type === SiteDesignPageType.Custom &&
    siteDesignPageResponse.linkType === SiteDesignPageLinkType.External
  ) {
    return generateExternalSubPageComponent({ siteDesignPageResponse });
  }

  throw new Error('Invalid page component type');
}
