import type { SiteDesignPageResponse } from '@flex-apis/recruiting';
import {
  SiteDesignPageLinkType,
  SiteDesignPageType,
} from '@flex-apis/recruiting';

import type { PageComponent } from './page-component';

export interface ExternalSubPageComponent extends PageComponent {
  readonly type: typeof SiteDesignPageType.Custom;
  readonly linkType: typeof SiteDesignPageLinkType.External;
  readonly blockComponentPageContent: null;
  readonly jobDescriptionPageContent: null;
}

export function isExternalSubPageComponent(
  pageComponent: PageComponent
): pageComponent is ExternalSubPageComponent {
  return (
    pageComponent.type === SiteDesignPageType.Custom &&
    pageComponent.linkType === SiteDesignPageLinkType.External
  );
}

export function generateExternalSubPageComponent(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): ExternalSubPageComponent;
export function generateExternalSubPageComponent(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): ExternalSubPageComponent {
  const { siteDesignPageResponse } = params;
  return {
    type: SiteDesignPageType.Custom,
    linkType: SiteDesignPageLinkType.External,
    title: siteDesignPageResponse.title,
    urlPagePath: siteDesignPageResponse.urlPagePath ?? null,
    href: siteDesignPageResponse.href ?? null,
    enabled: siteDesignPageResponse.enabled,
    blockComponentPageContent: null,
    jobDescriptionPageContent: null,
  };
}
